import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { rhythm } from '../utils/typography'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt, faClock } from "@fortawesome/free-solid-svg-icons"

const mapPointer = <FontAwesomeIcon icon={faMapMarkerAlt} />
const clock = <FontAwesomeIcon icon={faClock} />

const DateBox = (props) => (
    <EventDate>
        <InnerEvent>
            <Month>{props.month}</Month>
            <Day>{props.day}</Day>
            <Year>{props.year}</Year>
        </InnerEvent>
    </EventDate>
)

const Calendar = ({ showUpcoming = true, showPast = true, showDescription = true, size = "42px", limit = false, amount=4 }) => {
    const data = useStaticQuery(graphql`
    query NewsQuery {
        allMdx( filter: {fileAbsolutePath: {regex: "/(news)/" }}
                sort: { fields: [frontmatter___date], order: DESC }
                ) {
                    edges {
                        node {
                            excerpt(pruneLength: 50, truncate: true)
                            fields {
                                slug
                            }
                            frontmatter {
                                year: date(formatString: "YYYY")
                                month: date(formatString: "MMM")
                                day: date(formatString: "DD")
                                time: date(formatString: "HH:mm")
                                unixDate: date(formatString: "x")
                                title
                                description
                                location
                            }
                        }
                    }
                }
    }
    `)
    const news = data.allMdx.edges

    const upcoming = news.filter(item => item.node.frontmatter.unixDate >= Date.now()).reverse()
    const past = news.filter(item => item.node.frontmatter.unixDate < Date.now())
    const list = limit ? upcoming.slice(0,amount) : upcoming;

    return (
        <CalendarOuter>
            {showUpcoming &&
                <div>
                    <Heading size={size}>Upcoming events</Heading>
                    <Listing>
                        {upcoming.length === 0 && <p> No upcoming events{showPast ? <span>, check below for past events</span> : <Link to="/events"> <br />Click here to see a list of all events</Link>} </p>

                        }
                        {list.map(({ node }) => {
                            return (
                                <Link to={`/news${node.fields.slug}`} key={node.fields.slug}
                                    style={{
                                        textDecoration: 'none',
                                    }}>
                                    <Event >
                                        <DateBox year={node.frontmatter.year} day={node.frontmatter.day} month={node.frontmatter.month}></DateBox>
                                        <Content>
                                            <Title>{node.frontmatter.title}</Title>
                                            <Location>{clock} {node.frontmatter.time}</Location>
                                            <Location>{mapPointer} {node.frontmatter.location}</Location>
                                            {showDescription &&
                                                <Description>
                                                    {node.excerpt}
                                                </Description>
                                            }
                                        </Content>
                                    </Event>
                                </Link>
                            )
                        })}
                    </Listing>
                </div>
            }
            
            {showPast &&
            
                <div>
                    <div style={{
                marginBottom: "3rem"
            }}></div>
                    <Heading size="36px">Past events</Heading>
                    <Listing style={{filter: "grayscale(75%)"}}>
                        {past.map(({ node }) => {
                            return (
                                <Link to={`/news${node.fields.slug}`} key={node.fields.slug}
                                    style={{
                                        textDecoration: 'none',
                                    }}>
                                    <Event >
                                        <DateBox year={node.frontmatter.year} day={node.frontmatter.day} month={node.frontmatter.month}></DateBox>
                                        <Content>
                                            <Title>{node.frontmatter.title}</Title>
                                            <Location>{clock} {node.frontmatter.time}</Location>
                                            <Location>{mapPointer} {node.frontmatter.location}</Location>
                                            {showDescription &&
                                                <Description>
                                                    {node.excerpt}
                                                </Description>
                                            }
                                        </Content>
                                    </Event>
                                </Link>
                            )
                        })}
                    </Listing>
                </div>
            }
        </CalendarOuter>
    )
}

export default Calendar

//styles

const CalendarOuter = styled.div`

display: flex;

flex-direction: column;
margin: ${rhythm(2)} auto;
`
const Heading = styled.h2`
text-align: center;
font-size: ${props => props.size || "24px"};
margin-bottom: ${rhythm(1)};
`
const Listing = styled.div`

list-style: none;
margin-left: 0;
margin-bottom: ${rhythm(1)};
`
const Event = styled.div`

display: flex;
flex-direction: row;
min-height: 125px;
box-shadow: 0px 1px 3px ${props => props.theme.colors.grey};
background-color: ${props => props.theme.colors.light};
transition: filter 0.5s;
filter: brightness(100%);
margin: ${rhythm(1)} 0;
-webkit-transform-style: preserve-3d;
-webkit-backface-visibility: hidden;
&:hover {
    filter: brightness(105%);
}

`
const EventDate = styled.div`
display: flex;
align-items: center;
flex: 0 0 10vh;
max-width: 125px;
text-align: center;
justify-content: center;

padding: 0.25rem;
color: ${t => t.theme.colors.background};
background-color: ${props => props.theme.colors.primary};
`
const InnerEvent = styled.div`
display: flex;
flex-direction: column;

align-items: center;

`
const Month = styled.div`
font-size: 1.25rem;
`
const Day = styled.div`
font-size: 2.25rem;
line-height: 1.75rem;
`
const Year = styled.div`
font-size: 0.75rem;
letter-spacing: 0.2rem;
position: relative;
left: 2px;
`
const Content = styled.div`
flex: 2 1 auto;
border-left: 1px black solid;
padding-left: 0.5rem;
word-break: break-word;

`
const Title = styled.div`
font-size: 1.25rem;
font-weight: 600;
color: ${props => props.theme.colors.primary}
`
const Location = styled.div`
color: ${props => props.theme.colors.secondaryDark}
`
const Description = styled.div`
color: ${props => props.theme.colors.black}


`