import React from "react"
import { graphql } from "gatsby"
import Calendar from "../components/calendar"
import SEO from "../components/seo"
import Hero from "../components/hero"

const News = ({ data }) => {

  return (
    <div>
      <SEO
        title={data.mdx.frontmatter.heading}
        keywords={['Kristina bogataj concerts', 'choral concerts', 'upcoming concerts']}
      />
      <Hero imageSrc={data.mdx.frontmatter.hero.childImageSharp.fluid.src}
        opacity={0.75}
        color={'#262261'}>
        <h1>{data.mdx.frontmatter.heading}</h1>
      </Hero>
      <Calendar showDescription={true} />
    </div>
  )
}

export default News

export const query = graphql`
query News {
    mdx(fileAbsolutePath: {regex: "/events.md/"}) {
      body
      frontmatter {
        heading
        hero {
          childImageSharp {
            fluid(maxWidth: 2500 ) {
              src
            }
          }
        }
      }
    }
  }
  
`